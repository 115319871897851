<template>
  <div :id="id"
       :class="className"></div>
</template>

<script>
import * as echarts from "echarts";
var myChart;
export default {
  props: ["options", "id", "className", "max"],
  data () {
    return {};
  },
  mounted () {
    console.log(this.id)
    this.createChart();
  },
  methods: {
    createChart () {
      if (this.options.xAxisdata && this.options.seriesdata) {
        document.getElementById(this.id).style.width = "80%";
        document.getElementById(this.id).style.height = "400px";
        if(myChart != null && myChart != "" && myChart != undefined){
          myChart.dispose();//解决echarts dom已经加载的报错
        }
        myChart = echarts.init(document.getElementById(this.id));
        // myChart.on('click', function (params) {
        //     console.log(params);
        // })
        myChart.setOption({
          legend: {
            data: ['全市', '全校', '全班'],
            x: 'right',
          },
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                if (item.value) {
                  result += item.marker + " " + item.seriesName + " : " + item.value + "%</br>";
                } else {
                  result += item.marker + " " + item.seriesName + " : - </br>";
                }
              });
              return result;
            },
            
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {
                show: false
              }
            }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: "#5d5d5d"
              }
            },
            data: this.options.xAxisdata
          },
          yAxis: {
            axisLine: {
              lineStyle: {
                color: "#5d5d5d"
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed"
              }
            },
            type: "value"
            // min: 1,
            // max: this.max,
            // interval: parseFloat(((this.max - 1) / 4).toFixed(2))
          },
          series: this.options.seriesdata,

        });
      }
    }
  },

};
</script>
<style scoped>
</style>
